export const EnvironmentConfig = {
  Environment: process.env.REACT_APP_ENVIRONMENT ?? '',
  ApiUrl: process.env.REACT_APP_API_URL ?? '',
  TwoFactorClientId: process.env.REACT_APP_2FA_CLIENT_ID ?? '',
  TwoFactorClientSecret: process.env.REACT_APP_2FA_CLIENT_SECRET ?? '',
  AuthTokenKey: process.env.REACT_APP_AUTH_TOKEN_KEY ?? '',
  HomeUrl13Sick: process.env.REACT_APP_HOME_URL_13SICK ?? '',
  HomeUrlDrDr: process.env.REACT_APP_HOME_URL_DRDR ?? '',
  MobileVerificationClientId: process.env.REACT_APP_MOBILE_CLIENT_ID ?? '',
  MobileVerificationClientSecret: process.env.REACT_APP_MOBILE_CLIENT_SECRET ?? '',
  DeepLinkClientId: process.env.REACT_APP_DEEP_LINK_CLIENT_ID ?? '',
  DeepLinkClientSecret: process.env.REACT_APP_DEEP_LINK_CLIENT_SECRET ?? '',
  ExternalDeepLinkClientId: process.env.REACT_APP_EXTERNAL_DEEP_LINK_CLIENT_ID ?? '',
  ExternalDeepLinkClientSecret: process.env.REACT_APP_EXTERNAL_DEEP_LINK_CLIENT_SECRET ?? '',
  MapsApiKey: process.env.REACT_APP_MAPS_API_KEY ?? '',
  GoCardlessEnv: process.env.REACT_APP_GOCARDLESS_ENV ?? '',
  FacereUrl: process.env.REACT_APP_FACERE_URL ?? '',
  FacereAppId: process.env.REACT_APP_FACERE_APP_ID ?? '',
  TermsUrl: process.env.REACT_APP_TERMS_URL ?? '',
  PrivacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL ?? '',
  ContactUsUrl: process.env.REACT_APP_CONTACT_US_URL ?? '',
  MUIXLicense: process.env.REACT_APP_MUI_X_LICENSE ?? '',
  SkipAuthSMS: process.env.REACT_APP_SKIP_AUTH_SMS === 'true' ?? false,
  StripeKey: process.env.REACT_APP_STRIPE_KEY ?? '',

  ShowTwilioCall: process.env.REACT_APP_SHOW_TWILIO_CALL === 'true' ?? false,
};
