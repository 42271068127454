import React from 'react';

import { createTheme, Shadows } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

const colors = {
  azure: {
    100: '#0081F9',
    80: '#309CFF',
    60: '#72BBFF',
    40: '#AFD9FF',
    10: '#E9F4FF',
  },
  jade: {
    100: '#00AF70',
    80: '#21C997',
    60: '#1CEAAC',
    10: '#E2F6F0',
  },
  system: {
    error: '#EA4848',
    alert: '#FFA318',
    success: '#57F05D',
  },
  action: {
    prescribe: '#00774C',
    refer: '#7529B0',
    investigate: '#DA2323',
    certify: '#FF6B00',
    bill: '#0062BC',
  },
  grey: {
    100: '#3C3C3C',
    80: '#616161',
    60: '#8B8B8B',
    40: '#B6B6B6',
    20: '#DEDEDE',
    10: '#F1F1F1',
    5: '#FAFAFA',
  },
  neutral: {
    black: '#000000',
    white: '#FFFFFF',
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }

  //NOTE: ignore this otherwise eslint cleanup will change to implementation instead of extension
  //Not sure why MUI lets you set partial colors in theme but not use them in useTheme :think:
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorPartial {}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

declare module '@mui/material' {
  interface TypeBackground {
    contrast: string;
    alt: string;
  }
}

const intermediateTheme = createTheme({});
const themeInner = createTheme(intermediateTheme, {
  typography: {
    fontFamily: ['Work Sans'].join(','),
    h1: {
      fontWeight: 500,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '20px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '42px',
      },
    },
    h2: {
      fontWeight: 500,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '18px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '30px',
      },
    },
    h3: {
      fontWeight: 500,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '24px',
      },
    },
    h4: {
      fontWeight: 500,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '22px',
      },
    },
    h5: {
      fontWeight: 500,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
      },
    },
    h6: {
      fontWeight: 500,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '16px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '18px',
      },
    },
    body1: {
      fontWeight: 400,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '16px',
      },
    },
    body2: {
      fontWeight: 400,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '14px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '14px',
      },
    },
    body3: {
      fontWeight: 400,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '12px',
        lineHeight: '15px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '12px',
        lineHeight: '15px',
      },
    },
    body4: {
      fontWeight: 400,
      [intermediateTheme.breakpoints.down('lg')]: {
        fontSize: '10px',
      },
      [intermediateTheme.breakpoints.up('lg')]: {
        fontSize: '10px',
      },
    },
  },
  palette: {
    background: {
      default: colors.neutral.white,
      contrast: colors.azure['10'],
      alt: colors.grey['5'],
    },
    error: {
      main: colors.system.error,
    },
    success: {
      main: colors.system.success,
    },
    warning: {
      main: colors.system.alert,
    },
    info: {
      main: colors.azure['40'],
    },
    common: {
      black: colors.neutral.black,
      white: colors.neutral.white,
    },
    primary: {
      main: colors.azure['100'],
      '600': colors.azure['60'],
      '400': colors.azure['40'],
      '100': colors.azure['10'],
    },
    secondary: {
      main: colors.jade['100'],
      dark: '#008f5d',
      '800': colors.jade['80'],
      '600': colors.jade['60'],
      '100': colors.jade['10'],
      contrastText: colors.neutral.white,
    },
    text: {
      primary: colors.neutral.black,
      secondary: colors.grey['60'],
    },
    grey: {
      '900': colors.grey['100'],
      '800': colors.grey['80'],
      '600': colors.grey['60'],
      '400': colors.grey['40'],
      '200': colors.grey['20'],
      '100': colors.grey['10'],
      '50': colors.grey['5'],
    },

    /*
        *         100: "#3C3C3C",
        80: "#616161",
        60: "#8B8B8B",
        40: "#B6B6B6",
        20: "#DEDEDE",
        10: "#F1F1F1",
        5: "#FAFAFA"
        *
        * */
  },
  shadows: ['none', '0px 0px 15px 0px #0000000F', ...Array<string>(23).fill('none')] as Shadows,
  components: {
    MuiStack: {
      defaultProps: {
        flex: 1,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'span',
          body4: 'span',
        },
      },
    },
    //TODO: this breaks icon at start button
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'lowercase',
          display: 'inline-block', //TODO: This should be inline-flex not inline-block dont know why it is set like this
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'flex',
          textTransform: 'capitalize',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.neutral.white,
          boxShadow: 'none',
          borderBottom: `1px solid ${colors.grey['20']}`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          [intermediateTheme.breakpoints.up('md')]: {
            maxWidth: '672px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.grey['100'],
          '&.Mui-focused': {
            color: colors.azure['100'],
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 0px 15px 0px #0000000F',
          border: `1px solid ${colors.grey['20']} !important`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey['20'],
          },
          '.Mui-readOnly .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.grey['20']} !important`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: colors.neutral.white,
        },
        root: {
          backgroundColor: colors.neutral.white,
          '&.Mui-disabled': {
            backgroundColor: colors.grey['10'],
            '.MuiInputBase-input': {
              backgroundColor: colors.grey['10'],
            },
            fieldset: {
              borderColor: `${colors.grey['20']} !important`,
            },
          },
          '&:not(.Mui-disabled)': {
            '&:hover fieldset': {
              borderColor: `${colors.azure['100']} !important`,
            },
          },
          '&:not(.MuiInputBase-sizeSmall)': {
            '.MuiInputBase-input': {
              paddingTop: 12,
              paddingBottom: 12,
            },
          },
          '&.MuiInputBase-sizeSmall': {
            '&.MuiInputBase-adornedStart': {
              paddingLeft: 12,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          //backgroundColor: colors.neutral.white
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: colors.grey['100'],
          },
          '& label.Mui-focused': {
            color: colors.azure['100'],
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: colors.neutral.white,
            fieldset: {
              borderColor: colors.grey['20'],
            },
            '&:hover fieldset': {
              borderColor: colors.azure['100'],
            },
            '&.Mui-disabled': {
              backgroundColor: colors.grey['10'],
              '& fieldset': {
                borderColor: colors.grey['20'],
              },
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.azure['100'],
            },
            '&.Mui-error fieldset': {
              borderWidth: '2px',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: colors.grey['60'],
            textDecorationColor: colors.grey['60'],
            cursor: 'default',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: colors.neutral.white,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          borderTop: '4px solid white',
          '&.Mui-selected': {
            borderTop: `4px solid ${colors.jade['100']}`,
            color: colors.jade['100'],
            transition: 'border-color 300ms',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            backgroundColor: colors.jade['10'],
          },
          '&:last-of-type': {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
          '&:first-of-type': {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          },
        },
      },
    },
  },
});

const globalStyles = {};

const theme = themeInner;

export { theme, globalStyles, colors };
