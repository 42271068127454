import React from 'react';

import { Global } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';
import 'reflect-metadata';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import reportWebVitals from './reportWebVitals';

import { EnvironmentConfig } from '~/common/config/environment.config';
import { globalStyles } from '~/theme/theme';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

LicenseInfo.setLicenseKey(EnvironmentConfig.MUIXLicense);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'doctor') {
    return import('./doctor/index');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'patient') {
    return import('./patient/index');
  } else {
    return Promise.reject(new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET));
  }
}

// Guessing this is something Tofi is watching. Going to cut this off.
// Initialize Google Analytics
// ReactGA.initialize([
//     { trackingId: "G-49PVX244DF", gaOptions: { name: "GA4_Main" } },
//     { trackingId: "G-LVKDD4QKX0", gaOptions: { name: "GA4_Secondary" } },
//     { trackingId: "AW-11335063024", gaOptions: { name: "GoogleAds" } },
// ]);

// Import the entry point and render it's default export
void importBuildTarget().then(({ default: Application }) =>
  root.render(
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Global styles={globalStyles} />
        <Application />
      </LocalizationProvider>
    </React.StrictMode>,
  ),
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
